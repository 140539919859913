import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Form, Input, Modal, Row, Select } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import ReactCountryFlag from 'react-country-flag'
import Autocomplete from 'react-google-autocomplete'
import client from '../../../api/client'

const AddUserModal = (props) => {
  const { userDetails, isSender, formRef, isReceiver } = props
  const [provinceList, setProvinceList] = useState([])
  const [districtList, setDistrictList] = useState([])
  const [subDistrictList, setSubDistrictList] = useState([])
  const [provinceId, setProvinceId] = useState(null)
  const [permission, setPermission] = useState(null)
  const [isCompany, setIsCompany] = useState(userDetails && userDetails.isCompany && isSender ? userDetails.isCompany : false)

  useEffect(() => {
    return () => props.formRef.resetFields()
  }, [props.formRef])

  const fetchProvinceList = useCallback(() => {
    const endpoint = '/api/lookup/provinces'
    client
      .get(endpoint)
      .then(async (res) => {
        setProvinceList(
          res.data.map((province) => ({
            value: province.id,
            label: province.name,
          })),
        )
      })
      .catch((err) => console.error({ err }))
  }, [])

  const fetchDistrictList = useCallback((provinceId) => {
    const endpoint = `/api/lookup/provinces/${provinceId}/districts`
    client
      .get(endpoint)
      .then(async (res) => {
        await setDistrictList(
          res.data.map((province) => ({
            value: province.id,
            label: province.name,
          })),
        )
      })
      .catch((err) => console.error({ err }))
  }, [])

  const fetchSubDistrictList = useCallback(
    (districtId, provinceIdForEdit) => {
      const endpoint = `/api/lookup/provinces/${provinceIdForEdit || provinceId}/districts/${districtId}/subdistricts`
      client
        .get(endpoint)
        .then(async (res) => {
          await setSubDistrictList(
            res.data.map((province) => ({
              value: province.id,
              label: province.name,
            })),
          )
        })
        .catch((err) => console.error({ err }))
    },
    [provinceId],
  )

  useEffect(() => {
    if (props.showMGLAddress) fetchProvinceList()
  }, [fetchProvinceList, props.showMGLAddress])

  useEffect(() => {
    setDistrictList([])
    setSubDistrictList([])
  }, [provinceId])

  useEffect(() => {
    setPermission(props?.userDetails?.roles?.length > 0 ? props?.userDetails?.roles[0] : null)
    if (props.isEditting && props.userDetails?.provinceId) {
      fetchDistrictList(props.userDetails?.provinceId)
      fetchSubDistrictList(props.userDetails?.districtId, props.userDetails?.provinceId)
    } else {
      setSubDistrictList([])
      setDistrictList([])
    }
  }, [props.isEditting, fetchDistrictList, fetchSubDistrictList, props.userDetails])

  const handleChangeIsCompany = (val) => {
    formRef.setFieldsValue({
      firstName: '',
      lastName: '',
    })
    setIsCompany(val)
  }

  return (
    <Modal
      open={props.modalVisible}
      onCancel={props.closeModal}
      className="sb-modal"
      width={700}
      destroyOnClose
      centered
      footer={[
        <Row justify="center" key="add-user">
          <Col span={16}>
            <Button className="btn-sb btn-sb-primary w-100" form="add-user" htmlType="submit" loading={props.apiLoading}>
              {props.isEditting ? 'Хадгалах' : 'Нэмэх'}
            </Button>
          </Col>
        </Row>,
      ]}
    >
      <Form name="add-user" form={props.formRef} onFinish={props.onSubmit}>
        <Row gutter={[16, 0]}>
          <Col span={24} style={{ textAlign: 'center' }} className="mb-3">
            <h4>{props.modalTitle}</h4>
          </Col>
          {(isSender || isReceiver)&& (
            <Col span={24}>
              <Form.Item name="isCompany" label="Төрөл" initialValue={isCompany}>
                <Select
                  placeholder="Илгээгчийн төрөл"
                  className="sb-select sb-shadow"
                  defaultValue={false}
                  onChange={(val) => {
                    handleChangeIsCompany(val)
                  }}
                  options={[
                    { value: false, label: 'Хувь хүн' },
                    { value: true, label: 'Байгууллага' },
                  ]}
                />
              </Form.Item>
            </Col>
          )}
          {isCompany ? (
            <Col span={24}>
              <Form.Item
                name="firstName"
                label="Нэр"
                rules={[
                  {
                    required: isCompany ? true : false,
                    message: 'Байгууллагийн нэрээ оруулна уу!',
                  },
                ]}
              >
                <Input placeholder="Байгууллагийн нэр*" className="sb-input sb-shadow" />
              </Form.Item>
            </Col>
          ) : (
            <>
              {' '}
              <Col span={12}>
                <Form.Item
                  name="firstName"
                  label="Нэр"
                  rules={[
                    {
                      required: !isCompany ? true : false,
                      message: 'Нэрээ оруулна уу!',
                    },
                  ]}
                >
                  <Input placeholder="Нэр*" className="sb-input sb-shadow" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="lastName"
                  label="Овог"
                  rules={[
                    {
                      required: !isCompany ? true : false,
                      message: 'Овгоо оруулна уу!',
                    },
                  ]}
                >
                  <Input placeholder="Овог*" className="sb-input sb-shadow" />
                </Form.Item>
              </Col>
            </>
          )}
          {props.showRole && (
            <>
              <Col span={!props.isEditting ? 10 : 12}>
                <Form.Item
                  name="userName"
                  label="Нэвтрэх нэр"
                  rules={[
                    {
                      required: true,
                      message: 'Хэрэглэгчийн нэвтрэх нэрийг оруулна уу!',
                    },
                  ]}
                >
                  <Input placeholder="Нэвтрэх нэр" className="sb-input sb-shadow" />
                </Form.Item>
              </Col>
              <Col flex="auto">
                <Form.Item
                  name="roles"
                  label="Эрх"
                  rules={[
                    {
                      required: true,
                      message: 'Эрх оруулна уу!',
                    },
                  ]}
                >
                  <Select
                    placeholder="Эрх"
                    className="sb-select sb-shadow"
                    popupClassName="sb-select-dropdown"
                    suffixIcon={<FontAwesomeIcon icon={faChevronDown} size="1x" className="me-2" style={{ color: '#11427f' }} />}
                    onChange={(e) => {
                      if (
                        (permission === 'Employee' && (e === 'Admin' || e === 'Manager' || e === 'Owner')) ||
                        (e === 'Employee' && (permission === 'Admin' || permission === 'Manager' || permission === 'Owner'))
                      )
                        props.formRef.setFieldsValue({
                          phoneNumber: '',
                        })
                      setPermission(e)
                    }}
                    options={[
                      { value: 'Admin', label: 'Админ' },
                      { value: 'Manager', label: 'Менежер' },
                      { value: 'Employee', label: 'Ажилтан' },
                      { value: 'Owner', label: 'Эзэмшигч' },
                    ]}
                  />
                </Form.Item>
              </Col>
              {permission ? (
                <Col flex="51px">
                  <ReactCountryFlag
                    svg
                    countryCode={permission === 'Employee' ? 'MN' : 'US'}
                    style={{
                      width: '35px',
                      height: '23px',
                      marginTop: 40,
                    }}
                  />
                </Col>
              ) : (
                ''
              )}
              {!props.isEditting && (
                <Col span={6}>
                  <Form.Item
                    name="password"
                    label="Пин код"
                    rules={[
                      {
                        required: true,
                        message: 'Пин код оруулна уу!',
                      },
                      {
                        len: 6,
                        message: 'Пин код 6-оронтой байх шаардлагатай',
                      },
                    ]}
                  >
                    <Input placeholder="Пин код" type="number" className="sb-input sb-shadow" />
                  </Form.Item>
                </Col>
              )}
            </>
          )}
          {permission ? (
            <>
              <Col span={12}>
                <Form.Item
                  name="phoneNumber"
                  label="Утас"
                  rules={[
                    {
                      required: true,
                      message: 'Утас оруулна уу!',
                    },
                  ]}
                >
                  <Input placeholder="Утас" className="sb-input sb-shadow" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="email"
                  label="Имэйл"
                  rules={
                    props.isReceiver
                      ? [
                          {
                            type: 'email',
                            message: 'Та зөв форматтай хаяг оруулна уу!',
                          },
                        ]
                      : [
                          {
                            required: true,
                            message: 'Имэйл оруулна уу!',
                          },
                          {
                            type: 'email',
                            message: 'Та зөв форматтай хаяг оруулна уу!',
                          },
                        ]
                  }
                >
                  <Input placeholder="Имэйл" className="sb-input sb-shadow" />
                </Form.Item>
              </Col>
            </>
          ) : (
            <>
              <Col span={12}>
                <Form.Item
                  name="phoneNumber"
                  label="Утас"
                  rules={[
                    {
                      required: true,
                      message: 'Утас оруулна уу!',
                    },
                  ]}
                >
                  <Input placeholder="Утас" className="sb-input sb-shadow" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="email"
                  label="Имэйл"
                  rules={
                    props.isReceiver
                      ? [
                          {
                            type: 'email',
                            message: 'Та зөв форматтай хаяг оруулна уу!',
                          },
                        ]
                      : [
                          {
                            required: true,
                            message: 'Имэйл оруулна уу!',
                          },
                          {
                            type: 'email',
                            message: 'Та зөв форматтай хаяг оруулна уу!',
                          },
                        ]
                  }
                >
                  <Input placeholder="Имэйл" className="sb-input sb-shadow" />
                </Form.Item>
              </Col>
            </>
          )}
          {props.showUSAAddress && (
            <Col span={24}>
              <Form.Item
                name="address"
                label="Xаяг"
                rules={[
                  {
                    required: true,
                    message: 'Xаягаа оруулна уу!',
                  },
                ]}
              >
                <Autocomplete
                  className="ant-input sb-shadow google-map-input"
                  apiKey={'AIzaSyC8meMe-1DKxh70DZotYw6z-CVj4dSpnbc'}
                  onPlaceSelected={async (place) => {
                    props.formRef.setFieldsValue({
                      address: place.formatted_address,
                    })
                  }}
                  options={{
                    types: ['address'],
                    componentRestrictions: { country: 'US' },
                  }}
                />
              </Form.Item>
            </Col>
          )}
          {props.showMGLAddress && (
            <>
              <Col span={24} className="mb-3" style={{ fontSize: 18 }}>
              {isCompany ? 'Байгууллагын' : 'Гэрийн'} хаяг
              </Col>
              <Col span={12}>
                <Form.Item
                  name="provinceId"
                  label="Хот/Аймаг"
                  rules={[
                    {
                      required: true,
                      message: 'Хот/Аймаг оруулна уу!',
                    },
                  ]}
                >
                  <Select
                    className="sb-select sb-shadow"
                    popupClassName="sb-select-dropdown"
                    placeholder="Хот/Аймаг"
                    suffixIcon={<FontAwesomeIcon icon={faChevronDown} size="1x" style={{ color: '#11427f' }} />}
                    // showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }}
                    options={provinceList}
                    onChange={async (id) => {
                      setProvinceId(id)
                      await fetchDistrictList(id)
                      props.formRef.setFieldsValue({
                        districtId: null,
                        subdistrictId: null,
                      })
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="districtId"
                  label="Дүүрэг/Сум"
                  rules={[
                    {
                      required: true,
                      message: 'Дүүрэг/Сум оруулна уу!',
                    },
                  ]}
                >
                  <Select
                    className="sb-select sb-shadow"
                    popupClassName="sb-select-dropdown"
                    placeholder="Дүүрэг/Сум"
                    // showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }}
                    suffixIcon={<FontAwesomeIcon icon={faChevronDown} size="1x" style={{ color: '#11427f' }} />}
                    options={districtList}
                    onChange={async (id) => {
                      await fetchSubDistrictList(id)
                      props.formRef.setFieldsValue({
                        subdistrictId: null,
                      })
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="subdistrictId"
                  label="Хороо/Баг"
                  rules={[
                    {
                      required: true,
                      message: 'Хороо/Баг оруулна уу!',
                    },
                  ]}
                >
                  <Select
                    className="sb-select sb-shadow"
                    popupClassName="sb-select-dropdown"
                    placeholder="Хороо/Баг"
                    // showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }}
                    suffixIcon={<FontAwesomeIcon icon={faChevronDown} size="1x" style={{ color: '#11427f' }} />}
                    options={subDistrictList}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="address" label="Байр">
                  <Input className="sb-input sb-shadow" placeholder="Байр" />
                </Form.Item>
              </Col>
            </>
          )}
          {props.showRole && (
            <Col span={12}>
              <Form.Item name="printerIP" label="Принтер IP хаяг">
                <Input placeholder="Принтер IP хаяг" className="sb-input sb-shadow" />
              </Form.Item>
            </Col>
          )}
        </Row>
      </Form>
    </Modal>
  )
}

export default AddUserModal
