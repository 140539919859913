import React, { useState, useEffect } from 'react'
import { faEye, faCheckCircle, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import client from '../../../api/client'
import { Row, Col, Table, Button, Form, notification, Modal } from 'antd'
import { BranchModal } from '..'
const tableTitleStyle = {
  display: 'flex',
  alignItems: 'center',
  fontSize: 24,
}
export const formatPhone = (number) => {
  if (number) {
    const cleaned = number.replace(/\D/g, '').substring(0, 10)

    const zip = cleaned.substring(0, 3)
    const middle = cleaned.substring(3, 6)
    const last = cleaned.substring(6, 10)

    let ret = ''
    if (cleaned.length > 6) ret = `(${zip}) ${middle}-${last}`
    else if (cleaned.length > 3) ret = `(${zip}) ${middle}`
    else if (cleaned.length > 0) ret = `(${zip}`
    return ret
  }
  return ''
}
const Branches = (props) => {
  const [editBranchFormRef] = Form.useForm()
  const [branchModalVisible, setBranchModalVisible] = useState(false)
  const [isReceiveOnline, setIsReceiveOnline] = useState(false)
  const [branchId, setBranchId] = useState(null)
  const [loading, setLoading] = useState(false)
  const fetchBranchInfo = async (id) => {
    const endpoint = `/api/lookup/branches/${id}`
    client
      .get(endpoint)
      .then(async (res) => {
        setBranchId(id)
        setIsReceiveOnline(res.data.isReceiveOnline)
        await editBranchFormRef.setFieldsValue({
          ...res.data,
          phone: formatPhone(res.data.phone),
          staffAccountIds: res.data.accounts.map((staff) => staff.id),
          code: res.data.code !== '\u0000' ? res.data.code : '',
        })
        setBranchModalVisible(true)
      })
      .catch((err) => console.error({ err }))
  }

  const deleteBranch = (id) => {
    Modal.confirm({
      title: 'Салбар устгах гэж байна!',
      content: 'Та салбарын мэдээлэл устгах гэж байна. Үргэлжлүүлэх үү?',
      okText: 'Устга',
      cancelText: 'Болих',
      onOk: () => {
        setLoading(true)
        client
          .delete(`/api/lookup/branches/${id}`)
          .then((res) => {
            if (res.status === 200) {
              notification.success({ message: 'Амжилттай салбарыг устгалаа' })
              props.fetchBrancheList()
            }
          })
          .finally(() => setLoading(false))
      },
    })
  }

  useEffect(() => {
    if (!branchModalVisible) {
      setBranchId(null)
      setIsReceiveOnline(false)
    }
  }, [branchModalVisible])

  const branchColumns = [
    {
      title: 'Нэр',
      className: 'text-center',
      dataIndex: 'name',
      width: '25%',
    },
    {
      title: 'Хаяг',
      className: 'text-center',
      dataIndex: 'address',
      width: '30%',
    },
    {
      title: 'Үнэ/кг',
      className: 'text-center',
      dataIndex: 'defaultPerKgPrice',
      width: '10%',
      render: (price) => `$${price}/кг`,
    },
    {
      title: 'Утас',
      className: 'text-center',
      dataIndex: 'phone',
      width: '15%',
      render: (phone) => formatPhone(phone),
    },
    {
      title: 'Онлайн ачаа авах эсэх',
      className: 'text-center',
      width: '15%',
      render: (record) => record.isReceiveOnline && <FontAwesomeIcon style={{ color: '#86C357' }} icon={faCheckCircle} size="1x" className="mx-2" />,
    },
    {
      title: 'Үйлдэл',
      className: 'text-center',
      width: '5%',
      render: (record) => (
        <div style={{ display: 'flex', gap: '4px' }}>
          <Button className="btn-sb btn-sb-danger w-100" onClick={() => deleteBranch(record.id)}>
            <FontAwesomeIcon icon={faTrash} size="sm" className="mx-2" />
          </Button>
          <Button className="btn-sb btn-sb-primary w-100" onClick={() => fetchBranchInfo(record.id)}>
            <FontAwesomeIcon icon={faEye} size="sm" className="mx-2" />
          </Button>
        </div>
      ),
      // Hidden action
      hidden: !props.isOwnerOrAdmin,
    },
  ].filter((c) => !c.hidden)

  const editBranch = async (values) => {
    const endpoint = `/api/lookup/branches/${branchId}`
    const data = {
      ...values,
      sortOrder: parseInt(values.sortOrder),
    }
    data.isReceiveOnline = isReceiveOnline

    setLoading(true)
    client
      .put(endpoint, data)
      .then(async (res) => {
        props.fetchBrancheList()
        notification.success({
          message: 'Салбарын мэдээлэл амжилттай шинэчлэгдлээ!',
        })
        setBranchModalVisible(false)
      })
      .catch((err) => console.error({ err }))
      .finally(() => setLoading(false))
  }

  return (
    <>
      <Col span={24}>
        <Row gutter={[8, 8]}>
          <Col flex="auto" style={tableTitleStyle}>
            <span>Салбарын жагсаалт ({props.branchesInfo.dataCount})</span>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Table
            className="sb-table w-100 mt-3"
            dataSource={props.branchesInfo.tableData}
            columns={branchColumns}
            rowKey={(record) => record.id}
            locale={{ emptyText: 'Мэдээлэл байхгүй байна' }}
            pagination={false}
            loading={props.loading || loading}
          />
        </Row>
      </Col>
      {branchModalVisible && (
        <BranchModal
          modalVisible={branchModalVisible}
          closeModal={() => setBranchModalVisible(false)}
          formRef={editBranchFormRef}
          handleSubmit={editBranch}
          isReceiveOnline={isReceiveOnline}
          setIsReceiveOnline={(value) => setIsReceiveOnline(value)}
          staffList={props.staffList}
          isEditing={true}
        />
      )}
    </>
  )
}

export default Branches
