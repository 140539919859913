import React from 'react'
import { Row, Col, Button, Modal, Form, Input, Checkbox, Select } from 'antd'
import { formatPhone } from './branches'
import { getLocalStorageItem } from '../../../constants/helper-functions'
const BranchModal = (props) => {
  return (
    <Modal
      open={props.modalVisible}
      onCancel={props.closeModal}
      className="sb-modal"
      title="Салбарын мэдээлэл"
      width={600}
      destroyOnClose
      centered
      footer={[
        <Row justify="center" key="branch-modal">
          <Col span={24}>
            <Button className="btn-sb btn-sb-primary w-100" form="shipment-details" htmlType="submit">
              Хадгалах
            </Button>
          </Col>
        </Row>,
      ]}
    >
      <Form name="shipment-details" form={props.formRef} onFinish={props.handleSubmit}>
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Салбарын нэр оруулна уу!',
                },
              ]}
            >
              <Input placeholder="Нэр*" className="sb-input sb-shadow" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="phone"
              rules={[
                {
                  required: true,
                  message: 'Утасны дугаар оруулна уу!',
                },
                {
                  len: 14,
                  message: 'Утасны дугаарын орон дутуу байна!',
                },
              ]}
            >
              <Input
                placeholder="Утас*"
                className="sb-input sb-shadow"
                maxLength={14}
                onChange={(e) => props.formRef.setFieldValue('phone', formatPhone(e.target.value))}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="address"
              rules={[
                {
                  required: true,
                  message: 'Салбарын хаяг оруулна уу',
                },
              ]}
            >
              <Input placeholder="Хаяг*" className="sb-input sb-shadow" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="code"
              rules={[
                {
                  required: true,
                  message: 'Салбарын үсэг оруулна уу',
                },
              ]}
            >
              <Input
                placeholder="Салбарын үсэг"
                className="sb-input sb-shadow"
                maxLength={1}
                disabled={!!props.isEditing && !!props.formRef.getFieldValue('code')}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="defaultPerKgPrice"
              rules={[
                {
                  required: true,
                  message: '1кг ачааны үнэ оруулна уу',
                },
              ]}
            >
              <Input placeholder="Үнэ*" className="sb-input sb-shadow" prefix="$" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="sortOrder"
              rules={[
                {
                  required: true,
                  message: 'Дарааллын тоог оруулна уу',
                },
              ]}
            >
              <Input type="number" placeholder="Дараалал*" className="sb-input sb-shadow" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="staffAccountIds"
              rules={[
                {
                  required: true,
                  message: 'Багадаа 1 ажилтан сонгоно уу',
                },
              ]}
              initialValue={[parseInt(getLocalStorageItem('accountId'))]}
            >
              <Select
                mode="multiple"
                placeholder="Хариуцсан ажилтнууд*"
                className="sb-multi-select sb-shadow"
                options={
                  props.staffList?.map((staff) => ({
                    value: staff.id,
                    label: `${staff.firstName} ${staff.lastName}`,
                    disabled: staff.id === parseInt(getLocalStorageItem('accountId')),
                    title: staff.id === parseInt(getLocalStorageItem('accountId')) && 'Өөрийгөө устгах боломжгүй',
                  })) ?? []
                }
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item name="isReceiveOnline" className="mb-0">
              <Checkbox checked={props.isReceiveOnline} onChange={(e) => props.setIsReceiveOnline(e.target.checked)}>
                Онлайн ачаа авна
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default BranchModal
