import React, { useState, useEffect, useReducer, useCallback } from 'react'
import { Row, Col, Button, Table, Input, Select, Form, Upload, notification, Spin, Switch } from 'antd'
import { Helmet } from 'react-helmet'
import 'bootstrap/scss/bootstrap.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faChevronDown, faPencilAlt, faTimes } from '@fortawesome/free-solid-svg-icons'
import { faUpload } from '@fortawesome/free-solid-svg-icons'
import client from '../../../api/client'
import SearchFieldWithKeyUpListener from '../../search-field-with-key-up-listener'

const ShipmentTypes = (props) => {
  const [formRef] = Form.useForm()
  const [typeList, setTypeList] = useState({ data: [], total: 0 })
  const [loading, setLoading] = useState(false)
  const [uploadFile, setUploadFile] = useState(null)
  const [uPost, setUPost] = useState([])
  const [itemStatusLoading, setItemStatusLoading] = useState([])
  const [itemUPostLoading, setItemUPostLoading] = useState([])
  const [itemAttachmentLoading, setItemAttachmentLoading] = useState([])
  const [itemNameLoading, setItemNameLoading] = useState(0)
  const [itemNameEditing, setItemNameEditing] = useState({ id: 0, value: '' })

  const [paginatedData, updatePaginatedData] = useReducer((curr, updated) => ({ ...curr, ...updated }), {
    search: '',
    pagination: {
      current: 1,
      pageSize: 10,
    },
    sorter: {
      field: 'id',
      order: 'descend',
    },
    isActive: true,
    includeContainerShipmentType: true,
  })

  useEffect(() => {
    const init = async () => {
      const res = await client.get(`/api/lookup/uposttypes`)
      setUPost(res.data.map((el) => ({ value: el.id, label: el.name })))
    }
    init()
  }, [])

  const onChangeAttachment = (record, value) => {
    changeAttachment(record, value)
  }

  const columns = [
    {
      title: '#',
      className: 'text-center',
      width: '8%',
      dataIndex: 'code',
    },
    {
      title: '',
      className: 'text-center',
      dataIndex: 'type',
      width: '10%',
      responsive: ['md'],
      render: (attachment, record) => {
        return (
          <Spin spinning={itemAttachmentLoading.includes(record.id)}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '4px', position: 'relative' }}>
              <div
                style={{
                  width: 30,
                  height: 30,
                  backgroundImage: `url(${attachment})`,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                }}
              >
                {/* <img src={attachment} /> */}
              </div>
              {props.isOwnerOrAdmin && (
                <div style={{ position: 'absolute', top: '50%', left: '50%' }} title="зураг солих">
                  <Upload
                    showUploadList={true}
                    fileList={uploadFile}
                    customRequest={({ file, onSuccess }) => {
                      setTimeout(() => {
                        onSuccess('ok', file)
                      }, 100)
                    }}
                    accept="image/png, image/jpeg"
                    maxCount={1}
                    onChange={({ file }) => onChangeAttachment(record, file.originFileObj)}
                    onPreview={(file) => console.log(file)}
                    progress={{
                      strokeColor: { '0%': '#108ee9', '100%': '#87d068' },
                      strokeWidth: 3,
                      format: (percent) => `${parseFloat(percent.toFixed(2))}%`,
                    }}
                  >
                    <FontAwesomeIcon icon={faUpload} size="1x" style={{ color: '#11427f' }} />
                  </Upload>
                </div>
              )}
            </div>
          </Spin>
        )
      },
    },
    {
      title: 'Нэр',
      className: 'text-left',
      dataIndex: 'name',
      responsive: ['md'],
      render: (name, record) => {
        if (itemNameEditing.id === record.id) {
          return (
            <Spin spinning={itemNameLoading === record.id}>
              <Input
                value={itemNameEditing.value}
                onChange={(e) => setItemNameEditing({ id: record.id, value: e.target.value })}
                className="sb-input sb-shadow"
                placeholder="Нэр*"
                suffix={
                  <div style={{ display: 'flex', gap: '4px' }}>
                    <div
                      style={{ cursor: 'pointer' }}
                      role="button" // Indicate this is a button-like element
                      tabIndex="0" // Make it focusable
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          setItemNameEditing({ id: 0, value: '' })
                        }
                      }}
                      onClick={() => {
                        setItemNameEditing({ id: 0, value: '' })
                      }}
                    >
                      <FontAwesomeIcon icon={faTimes} size="1x" style={{ color: 'red' }} />
                      {''}
                    </div>

                    <div
                      style={{ cursor: 'pointer' }}
                      role="button" // Indicate this is a button-like element
                      tabIndex="-1" // Make it focusable
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          changeName(record.id, itemNameEditing.value)
                        }
                      }}
                      onClick={() => {
                        changeName(record.id, itemNameEditing.value)
                      }}
                    >
                      <FontAwesomeIcon icon={faCheck} size="1x" style={{ color: 'green' }} />
                      {''}
                    </div>
                  </div>
                }
              />
            </Spin>
          )
        } else {
          return (
            <div style={{ display: 'flex', alignItems: 'center', gap: '4px', justifyContent: 'space-between' }}>
              {name}
              {props.isOwnerOrAdmin && (
                <div
                  style={{ cursor: 'pointer' }}
                  role="button" // Indicate this is a button-like element
                  tabIndex="-2" // Make it focusable
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      setItemNameEditing({ id: 0, value: '' })
                    }
                  }}
                  onClick={() => {
                    setItemNameEditing({ id: record.id, value: name })
                  }}
                  title="нэр солих"
                >
                  <FontAwesomeIcon icon={faPencilAlt} size="1x" style={{ color: '#11427f' }} />
                  {''}
                </div>
              )}
            </div>
          )
        }
      },
    },
    {
      title: 'U-Post төрөл',
      className: 'text-left',
      responsive: ['md'],
      render: (record) => {
        if (props.isOwnerOrAdmin)
          return (
            <Spin spinning={itemUPostLoading.includes(record.id)}>
              <Select
                value={record.upostId}
                placeholder="Төрөл сонгоно уу"
                className="w-100 sb-shadow sb-select"
                popupClassName="sb-select-dropdown"
                options={uPost}
                optionFilterProp="children"
                suffixIcon={<FontAwesomeIcon icon={faChevronDown} size="1x" style={{ color: '#11427f' }} />}
                onChange={(e) => changeUPost(record.id, e)}
              />
            </Spin>
          )

        return record.upost
      },
    },
    {
      // Hidden action
      hidden: !props.isOwnerOrAdmin,
      title: 'Төлөв',
      className: 'text-center',
      width: '8%',
      dataIndex: 'isActive',

      render: (status, record) => {
        return (
          <Spin spinning={itemStatusLoading.includes(record.id)}>
            <Switch checked={status} onChange={(checked) => changeStatus(record.id, checked)} />
          </Spin>
        )
      },
    },
  ].filter((c) => !c.hidden)

  const fetchTypeList = useCallback(() => {
    setLoading(true)
    client
      .post(`/api/lookup/orderitemtypes/paginated`, paginatedData)
      .then((res) => {
        if (res.status === 200) {
          const list = res.data.data.map((el) => {
            return {
              id: el.id,
              code: el.id,
              type: el.url,
              name: el.name,
              upost: el.uPostTypeName,
              upostId: el.uPostTypeId,
              isActive: el.isActive,
            }
          })
          setTypeList({ data: list, total: res.data.total })
        }
      })
      .finally(() => setLoading(false))
  }, [paginatedData])

  useEffect(() => {
    fetchTypeList()
  }, [fetchTypeList])

  const changeStatus = (itemId, value) => {
    setItemStatusLoading((prev) => [...prev, itemId])
    client
      .patch(`/api/lookup/orderitemtypes/${itemId}/status`, [{ operationType: 'replace', path: 'isActive', op: 'replace', value: value }])
      .then((res) => {
        if (res.status === 200) {
          fetchTypeList()
          notification.success({ message: 'Амжилттай төлөв солилоо' })
        }
      })
      .finally(() => setItemStatusLoading((prev) => prev.filter((c) => c !== itemId)))
  }

  const changeUPost = async (itemId, value) => {
    setItemUPostLoading((prev) => [...prev, itemId])
    client
      .patch(`/api/lookup/orderitemtypes/${itemId}/status`, [{ operationType: 'replace', path: 'uPostTypeId', op: 'replace', value: value }])
      .then((res) => {
        if (res.status === 200) {
          fetchTypeList()
          notification.success({ message: 'Амжилттай U-Post төрөл солилоо' })
        }
      })
      .finally(() => setItemUPostLoading((prev) => prev.filter((c) => c !== itemId)))
  }

  const changeName = async (itemId, value) => {
    setItemNameLoading(itemId)
    client
      .patch(`/api/lookup/orderitemtypes/${itemId}/status`, [{ operationType: 'replace', path: 'name', op: 'replace', value: value }])
      .then((res) => {
        if (res.status === 200) {
          fetchTypeList()
          notification.success({ message: 'Амжилттай нэр солилоо' })
          setItemNameEditing({ id: 0, value: '' })
        }
      })
      .finally(() => setItemNameLoading(0))
  }

  const changeAttachment = async (record, value) => {
    setItemAttachmentLoading((prev) => [...prev, record.id])
    const formData = new FormData()
    formData.append('Image', value)

    client
      .put(`/api/lookup/orderitemtypes/${record.id}`, formData)
      .then((res) => {
        if (res.status === 200) {
          fetchTypeList()

          notification.success({ message: 'Амжилттай зураг солилоо' })
        }
      })
      .finally(() => setItemAttachmentLoading((prev) => prev.filter((c) => c !== record.id)))
  }

  const handleSubmit = (values) => {
    const formData = new FormData()
    formData.append('Name', values.Name)
    formData.append('UPostTypeId', values.UPostTypeId)
    formData.append('Image', values.Image.file.originFileObj)

    client.post(`/api/lookup/orderitemtypes`, formData).then(async (res) => {
      notification.success({ message: 'Амжилттай илгээмжийн төрөл нэмлээ' })
      fetchTypeList()
      formRef.resetFields()
      setUploadFile([])
    })
  }

  return (
    <>
      <Helmet title="Илгээмжийн төрөл" defer={false} />
      <Row gutter={[30, 0]}>
        <Col span={props.isOwnerOrAdmin ? 16 : 24}>
          <Row className="sb-header" align={'middle'}>
            <Col flex="auto">
              <span className="sb-text-medium">Илгээмжийн төрлүүд ({typeList.total})</span>
            </Col>
            <Col flex="255px" style={{ marginLeft: '40px' }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '4px', fontSize: '16px' }}>
                Зөвхөн идэвхтэйг харах
                <Switch
                  checked={paginatedData.isActive}
                  onChange={(checked) => updatePaginatedData({ isActive: checked, pagination: { pageSize: 10, current: 1 } })}
                />
              </div>
            </Col>
            <Col flex="255px" style={{ marginLeft: '40px' }}>
              <SearchFieldWithKeyUpListener
                callback={(search) => updatePaginatedData({ search: search, pagination: { pageSize: 10, current: 1 } })}
                placeholder="Нэр, U-Post төрлөөр хайх"
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Table
                className="sb-table mt-3"
                dataSource={typeList.data}
                columns={columns}
                rowKey={(record) => record.id}
                locale={{ emptyText: 'Мэдээлэл байхгүй байна' }}
                loading={loading}
                pagination={{ total: typeList.total, ...paginatedData.pagination }}
                onChange={(pagination, filters, sorter) => {
                  updatePaginatedData({ pagination })
                }}
                scroll={{ y: 400 }}
              />
            </Col>
          </Row>
        </Col>
        {props.isOwnerOrAdmin && (
          <Col span={8}>
            <div className="sb-panel">
              <div className="sb-panel-header">Шинэ төрөл бүртгэх</div>
              <div style={{ padding: '20px 35px' }}>
                <Form name="new_type" form={formRef} onFinish={handleSubmit}>
                  <Form.Item className="mb-3" name="Name" rules={[{ required: true, message: 'Нэрээ оруулна уу!' }]}>
                    <Input className="sb-input sb-shadow" placeholder="Нэр*" />
                  </Form.Item>
                  <Form.Item name="UPostTypeId" rules={[{ required: true, message: 'Төрөл сонгоно уу!' }]}>
                    <Select
                      placeholder="Төрөл сонгоно уу"
                      className="w-100 sb-shadow sb-select"
                      popupClassName="sb-select-dropdown"
                      options={uPost}
                      optionFilterProp="children"
                      suffixIcon={<FontAwesomeIcon icon={faChevronDown} size="1x" style={{ color: '#11427f' }} />}
                    ></Select>
                  </Form.Item>
                  <Form.Item name="Image" rules={[{ required: true, message: 'Зурагаа оруулна уу!' }]}>
                    <Upload
                      showUploadList={true}
                      fileList={uploadFile}
                      customRequest={({ file, onSuccess }) => {
                        setTimeout(() => {
                          onSuccess('ok', file)
                        }, 100)
                      }}
                      accept="image/png, image/jpeg"
                      maxCount={1}
                      onChange={({ fileList }) => {
                        if (fileList.length > 0 && fileList[0].status === 'done') {
                          setUPost(fileList)
                        }
                      }}
                      progress={{
                        strokeColor: { '0%': '#108ee9', '100%': '#87d068' },
                        strokeWidth: 3,
                        format: (percent) => `${parseFloat(percent.toFixed(2))}%`,
                      }}
                    >
                      <FontAwesomeIcon icon={faUpload} size="2x" style={{ color: '#11427f', marginRight: '15px' }} />
                      <span style={{ fontSize: '18px' }}>Зураг оруулах</span>
                    </Upload>
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" className="w-100 btn-sb btn-sb-primary sb-shadow" htmlType="submit">
                      Хадгалах
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </Col>
        )}
      </Row>
    </>
  )
}
export default ShipmentTypes
